.contact-logo {
    max-height: 14rem;
    max-width: 14rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.contact-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-logo-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.social-links-title {
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.fun-contact-img {
    border-radius: 1rem;
    max-height: 45rem;
    max-width: 45rem;
    width: 100%;
}

.social-small-title {
    font-weight: 700;
    font-size: 1.5rem;
}

.contact-text-align-center {
    text-align: center;
    padding-bottom: 2rem;
}
