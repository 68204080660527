.nopage-container {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.nopage-img {
    max-width: 100%;
    max-height: 100%;
}
