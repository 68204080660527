.skill-title-div {
    text-align: center;
    margin-top: 1.5rem;
}

.skill-title {
    font-weight: 700;
    font-size: 2.5rem;
}

.sub-skill-title {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.skill-image-container {
    text-align: center;
}

.image-container-skill {
    min-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.skill-image {
    max-width: 100%;
    max-height: 4rem;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.skill-image-description {
    margin-top: 0.05rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: bold;
}

.skill-fun-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.skill-container {
    justify-content: center;
}

@media (min-width: 992px) {
    .image-container-skill {
        min-height: 5rem;
    }
    .skill-image {
        max-height: 5rem;
    }
    .image-description {
        font-size: 1rem;
    }
}

@media (max-width: 600px) {
    .image-container-skill {
        min-height: 2.5rem;
    }
    .skill-image {
        max-height: 2.5rem;
    }
    .image-description {
        font-size: 1rem;
    }
}

@media (max-width: 450px) {
    .image-container-skill {
        min-height: 2rem;
    }
    .skill-image {
        max-height: 2rem;
    }
    .image-description {
        font-size: 0.8rem;
    }
}

.skill-big-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 1rem;
}

.skill-fun {
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 1rem;
}

.skill-big-image-container {
    padding-bottom: 1rem;
}

.skills-small-padding {
    padding-bottom: 1rem;
}