.text-align {
    text-align: center;
}

.presentation-title {
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 2rem;
}

.presentation-section .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 2rem;
}

.presentation-section .me-image {
    max-width: 18rem;
    height: auto;
    border-radius: 1rem;
}

.presentation-section {
    padding-top: 2rem;
}

@media (max-width: 766px) {
    .presentation-text-container {
        text-align: center;
    }
}
