.user-card {
    display: flex;
    align-items: center;
}

.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.user-details {
    display: flex;
    align-items: center;
    color: black;
    font-weight: bold;
}

.user-reputation {
    margin-left: 0.3rem;
    margin-right: 0.2rem;
}

.user-badges {
    display: flex;
    align-items: center;
}

.badge {
    display: flex;
    align-items: center;
    color: black;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.2rem;
}

.badge-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.2rem;
}

.gold-badge .badge-circle {
    background-color: gold;
}

.silver-badge .badge-circle {
    background-color: silver;
}

.bronze-badge .badge-circle {
    background-color: #cd7f32;
}
