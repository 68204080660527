.logo-education {
    max-height: 10rem;
    max-width: 10rem;
    margin-bottom: 1rem;
}

.education-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.education-separator {
    width: 100%;
    height: 0.1rem;
    background: #E8E9EA;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.small-education-title {
    font-weight: bold;
}

.education-container {
}

.education-details {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

.no-padding-education-container {
    padding-left: 0;
    padding-right: 0;
}

.education-title {
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
