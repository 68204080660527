.thales-main-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 2rem;
}

.thales-sub-title {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 1rem;
}

.thales-sub-sub-title {
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.thales-logo-container {
    text-align: center;
    margin-bottom: 2rem;
}

.thales-logo {
    max-height: 6rem;
}

.thales-small-image {
    max-height: 13rem;
    max-width: 19rem;
}

.thales-image {
    max-height: 15rem;
    max-width: 100%;
}

.thales-big-image {
    max-height: 25rem;
    max-width: 100%;
}

.thales-map {
    max-height: 30rem;
    max-width: 100%;
    width: 100%;
    height: 20rem;
}

@media (min-width: 800px) {
    .thales-map {
        height: 40rem;
    }
}
