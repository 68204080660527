.quote-container1 {
    background-image: url('../../../assets/img/misc/background2.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    border-radius: 1rem;
}

.quote-text1  {
    font-size: 1.2rem;
    font-weight: 600;
    padding-top: 2rem;
    text-shadow: -2px 0 #222, 0 2px #222, 2px 0 #222, 0 -2px #222;
}

.quote-author-text1  {
    font-weight: 700;
    font-size: 2rem;
    padding-top: 1rem;
    text-shadow: -3px 0 #222, 0 3px #222, 3px 0 #222, 0 -3px #222;
}

.quote-author1 {
    padding-bottom: 2rem;
}
