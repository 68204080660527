.experiences-bold {
    font-weight: bold;
}

.experiences-header {
    text-align: center;
}

.experiences-title {
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.small-experience-logo {
    max-height: 1.3rem;
    max-width: 5rem;
}

.big-experience-logo {
    max-height: 4rem;
    max-width: 100%;
    margin-bottom: 1rem;
}

.accordion-button:not(.collapsed) {
    background-color: inherit;
    color: inherit;
}

.accordion-button:focus {
    box-shadow: none;
}

.experiences-sub-title {
    padding-bottom: 1rem;
    font-weight: bold;
}

.experiences-button {
    font-weight: bold;
    margin-left: 0.2rem;
    padding: 0;
}

.experiences-button:hover {
    font-weight: bold;
}

.experience-button-text {
    font-size: 1rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
