.sg-main-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 2rem;
}

.sg-sub-title {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 1rem;
}

.sg-sub-sub-title {
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.sg-logo-container {
    text-align: center;
    margin-bottom: 2rem;
}

.sg-no-margin {
    margin: 0;
}

.sg-small-margin {
    margin: 1rem;
}

.sg-logo {
    max-height: 6rem;
}

.sg-small-image {
    max-height: 13rem;
    max-width: 19rem;
}

.sg-image {
    max-height: 15rem;
    max-width: 100%;
}

.sg-big-image {
    max-height: 25rem;
    max-width: 100%;
}

.sg-big-big-image {
    max-height: 45rem;
    max-width: 100%;
    padding-bottom: 1rem;
}

.sg-small-padding {
    padding-top: 1rem;
}

.sg-padding {
    padding-top: 2rem;
}

.sg-image-description {
    text-align: center;
}

.sg-small-image-description {
    text-align: center;
    font-size: 0.7rem;
    font-style: italic;
}
