.banner-content {
    background-image: url('../../../assets/img/misc/background1.jpg');
    background-size: cover;
    background-position: center;
    text-align: center;
    text-shadow: -2px 0 #222, 0 2px #222, 2px 0 #222, 0 -2px #222;
    min-height: 50rem;
}

.banner .logo {
    max-width: 15rem;
    max-height: 15rem;
    margin-top: 15rem;
}

.banner .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.2rem;
    padding-bottom: 3rem;
}

.button {
    font-weight: bold;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.4) !important
}

.button:hover {
    font-weight: bold;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.6) !important
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
