.eoxis-main-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 2rem;
}

.eoxis-sub-title {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 1rem;
}

.eoxis-sub-sub-title {
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.eoxis-logo-container {
    text-align: center;
    margin-bottom: 2rem;
}

.eoxis-logo {
    max-height: 6rem;
}

.eoxis-small-image {
    max-height: 13rem;
    max-width: 19rem;
}

.eoxis-image {
    max-height: 15rem;
    max-width: 100%;
}

.eoxis-big-image {
    max-height: 25rem;
    max-width: 100%;
}

.eoxis-big-big-image {
    max-height: 45rem;
    max-width: 100%;
    padding-bottom: 1rem;
}

.eoxis-small-padding {
    padding-top: 1rem;
}

.eoxis-padding {
    padding-top: 2rem;
}
